import React from 'react';
import PropTypes from 'prop-types';

import GlobalStyle from 'global.css.js';

import Header from '../components/Header';
import HTMLHead from '../components/Head';
import Footer from '../components/Footer';
// import Banner from '../components/Banner';

const Layout = ({ children }) => (
  <>
    <HTMLHead />
    <GlobalStyle />
    <div>
      <Header />
      {children}
      <Footer />
    </div>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
