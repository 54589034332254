import React from 'react';
import { MdFormatQuote, MdClose } from 'react-icons/md';
import { TiSocialInstagram } from 'react-icons/ti';
import { FaFacebookF } from 'react-icons/fa';

const IconTable = {
  quote: <MdFormatQuote />,
  instagram: <TiSocialInstagram />,
  facebook: <FaFacebookF />,
  x: <MdClose />,
};

const Icon = ({ name = '', color = 'black', size = 25, children }) => {
  const IconFromName = IconTable[name];
  return IconFromName
    ? React.cloneElement(IconFromName, { color, size }, children)
    : null;
};

export default Icon;
