import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import config from '../../config';

import MEDIA from '../../helpers/mediaTemplates';

const applyPadding = (apply, vertical) => {
  if (apply && vertical) {
    return css`
      ${MEDIA.MOBILE`
        padding: ${config.sizes.spacing / 2}px;
      `};
      padding: ${config.sizes.spacing * 2}px;
    `;
  }

  if (apply && !vertical) {
    return css`
      ${MEDIA.MOBILE`
          padding: 0 ${config.sizes.spacing / 2}px;
      `};
      padding: 0 ${config.sizes.spacing * 2}px;
    `;
  }

  return css`
    padding: 0;
  `;
};

export const ContainerContent = styled.div`
  ${props => applyPadding(props.padding, props.verticalPadding)};
  max-width: ${config.breakpoints.DESKTOP}px;
  height: auto;
  margin: 0 auto;
`;

const Container = ({
  children,
  padding = true,
  verticalPadding = true,
  id,
}) => (
  <ContainerContent padding={padding} verticalPadding={verticalPadding} id={id}>
    {children}
  </ContainerContent>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  padding: PropTypes.bool,
  verticalPadding: PropTypes.bool,
  id: PropTypes.string,
};

export default Container;
