const config = {
  breakpoints: {
    DESKTOP: 1024,
    TABLET: 768,
    MOBILE: 576,
  },
  transition: {
    timeout: 150,
  },
  colors: {
    // primary: '#d43a2c',
    primary: '#e71c1c',
    alt: '#8F8F8F',
    white: '#f9f6ee',
    background: '#F4F4F4',
  },
  routes: {
    home: '/',
    gallery: '/gallery',
    contact: '/contact',
    news: '/news',
    covid: '/covid',
  },
  zIndex: {
    header: 1,
    dialog: 2,
  },
  sizes: {
    header: 70,
    footer: 100,
    spacing: 24, // used for padding / margins
  },
};

export default config;
