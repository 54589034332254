import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import Container from '../Container';

import config from '../../config';

import Media from '../../helpers/mediaTemplates';

const Root = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  background-color: ${config.colors.background};
  z-index: ${config.zIndex.header};
  border-bottom: ${props =>
    `1px solid rgba(230, 230, 230, ${props.borderOpacity})`};
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${config.sizes.header}px;
  width: 100%;

  a {
    color: #757575;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }
`;

const Links = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;

  li {
    height: 100%;
    padding: 2rem;
    ${Media.MOBILE`
      padding: 1rem;
    `}
    > a {
      color: ${config.colors.alt};
    }
  }
`;

const activeLinkStyles = {
  color: config.colors.primary,
  fontWeight: 'bold',
};

const HeaderLink = ({ title, path }) => (
  <li>
    <Link to={path} activeStyle={activeLinkStyles}>
      {title}
    </Link>
  </li>
);

HeaderLink.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

const Header = () => {
  const [borderOpacity, setBorderOpacity] = React.useState(0);

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fixed(width: 55, height: 55) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const onScroll = () => {
    const newScrollValue = window.scrollY;

    // If the scroll value is under 30, show no border
    if (newScrollValue < 30) {
      setBorderOpacity(0);
    } else if (newScrollValue <= 100) {
      // We subtract 30px from the scroll value to make up for the 30px we skipped
      // This makes more a smooth border scroll
      setBorderOpacity((newScrollValue - 30) / 100);
    } else if (newScrollValue >= 100) {
      // If they've scrolled more than 100px, set the opacity to max
      setBorderOpacity(1);
    }
  };

  React.useEffect(() => {
    if (window) {
      document.addEventListener('scroll', onScroll);

      return () => {
        document.removeEventListener('scroll', onScroll);
      };
    }
  }, []);

  return (
    <Root borderOpacity={borderOpacity}>
      <Container verticalPadding={false}>
        <Content>
          <Link to={config.routes.home}>
            <Img fixed={data.file.childImageSharp.fixed} />
          </Link>
          <Links>
            <HeaderLink title="News" path={config.routes.news} />
            <HeaderLink title="Gallery" path={config.routes.gallery} />
            <HeaderLink title="Contact" path={config.routes.contact} />
          </Links>
        </Content>
      </Container>
    </Root>
  );
};

export default Header;
